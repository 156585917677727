export interface UpdateProfileProps {
  name?: string
  avatar?: File
}

export class AuthManagementService {
  private readonly serviceUrl: string

  constructor(serviceUrl?: string) {
    if (!serviceUrl) {
      throw new Error('Service URL is required')
    }

    this.serviceUrl = serviceUrl
  }

  public async updateProfile(idToken: string, profile: UpdateProfileProps): Promise<void> {
    // save avatar first

    if (profile.avatar) {
      const formData = new FormData()
      formData.append('image', profile.avatar)
      const avatarResponse = await fetch(`${this.serviceUrl}/update-avatar`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        body: formData,
      })

      if (!avatarResponse.ok) {
        throw new Error('Error uploading avatar')
      }
    }

    // save name
    const response = await fetch('/api/update-profile/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({ name: profile.name }),
    })
    if (!response.ok) {
      throw new Error('Error updating profile')
    }
  }

  public async completeRegistration(idToken: string): Promise<void> {
    const response = await fetch('/api/complete-registration/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
      },
    })

    if (!response.ok) {
      throw new Error('Error completing registration')
    }
  }
}
