import type { Auth0UserProfile } from 'auth0-js'

export interface AdditionalClaims {
  connection_strategy: 'email' | 'google-oauth2'
  just_registered: boolean
}

export interface AuthState {
  idToken: string
  profile: Auth0UserProfile & AdditionalClaims
  expiresAt: number
}

export interface AuthStorageProps {
  saveAuthResult: (authResult: AuthResult, profile: Auth0UserProfile) => void
  isValidSession: () => boolean
  clearAuthResult: () => void
  getAuthState: () => AuthState | null
}

// session storage
// export class AuthStorage implements AuthStorageProps {
//   saveAuthResult(authResult: AuthResult, profile: Auth0UserProfile): void {
//     const { idToken, expiresIn } = authResult
//     const expiresAt = expiresIn * 1000 + new Date().getTime()
//     sessionStorage.setItem(
//       'auth0_authState',
//       JSON.stringify({ idToken, profile, expiresAt } satisfies AuthState)
//     )
//   }

//   isValidSession(): boolean {
//     const authState = this.getAuthState()
//     if (!authState) {
//       return false
//     }
//     return new Date().getTime() < authState.expiresAt - 300000
//   }

//   clearAuthResult(): void {
//     sessionStorage.removeItem('auth0_authState')
//   }

//   getAuthState(): AuthState | null {
//     const authState = sessionStorage.getItem('auth0_authState')
//     return authState ? (JSON.parse(authState) as AuthState) : null
//   }
// }

// In memory storage:
export class AuthStorage implements AuthStorageProps {
  private innerState: AuthState | null = null

  saveAuthResult(authResult: AuthResult, profile: Auth0UserProfile): void {
    const { idToken, expiresIn } = authResult
    this.innerState = {
      idToken,
      profile: profile as Auth0UserProfile & AdditionalClaims,
      expiresAt: expiresIn * 1000 + new Date().getTime(),
    }
  }

  isValidSession(): boolean {
    if (!this.innerState) {
      return false
    }

    // Check whether the current time is past the
    return new Date().getTime() < this.innerState.expiresAt - 300000
  }

  clearAuthResult(): void {
    this.innerState = null
  }

  getAuthState(): AuthState | null {
    return this.innerState
  }
}
