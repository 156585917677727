type CallbackFn = () => void

export class EventBus<EventType extends string> {
  private readonly listeners: Map<string, Set<CallbackFn>> = new Map()

  public on(event: EventType, listener: CallbackFn): void {
    const listeners = this.listeners.get(event)
    if (listeners) {
      listeners.add(listener)
    } else {
      this.listeners.set(event, new Set([listener]))
    }
  }

  public off(event: EventType, listener: CallbackFn): void {
    this.listeners.get(event)?.delete(listener)
  }

  public emit(event: EventType): void {
    this.listeners.get(event)?.forEach(listener => listener())
  }
}
