import { isServerSide } from './util'

/* eslint-disable no-console */
export function logError(...args: any[]): void {
  if (!isShowLogs()) {
    return
  }

  console.error(args)
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function logNotice(...args: any[]): void {
  if (!isShowLogs()) {
    return
  }

  console.log(args)
}

export function logWarning(...args: any[]): void {
  if (!isShowLogs()) {
    return
  }

  console.warn(args)
}

function isShowLogs(): boolean {
  if (isServerSide()) {
    return true
  }

  return localStorage.getItem('showLogs') === 'true'
}
