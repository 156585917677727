import { publicConfig } from 'config'
import type { AppEvent } from 'shared-code/app-events'
import { MapAppEventListeners } from 'shared-code/app-events-listeners'
import { gtmDatalayer } from 'shared-code/gtm'

export const appEventsListenersAA: Partial<MapAppEventListeners<AppEvent, 'event', void>> = {
  'poll-login-triggered': [
    e => {
      void gtmDatalayer({
        type: 'track',
        event: 'Poll login triggered',
        properties: {
          url: normalizeUrl(e.url),
          poll_question: e.question,
        },
      })
    },
  ],
  'user-authenticated': [
    e => {
      void gtmDatalayer({
        type: 'track',
        event: 'User Authenticated',
        properties: {
          url: normalizeUrl(e.url),
        },
      })
    },
  ],
  'poll-voted': [
    e => {
      void gtmDatalayer({
        type: 'track',
        event: 'Poll Response',
        properties: {
          url: normalizeUrl(e.url),
          poll_question: e.question,
          poll_answers: e.answer,
          current_responses: e.responsesCount,
        },
      })
    },
  ],
  'newsletter-signup': [
    e => {
      void gtmDatalayer({
        type: 'track',
        event: 'List Signup',
        properties: {
          url: normalizeUrl(e.url),
          cta: e.label,
          heading: e.heading,
          email: e.email,
        },
      })
    },
  ],
  'widget-interaction': [
    e => {
      void gtmDatalayer({
        type: 'track',
        event: 'Widget Clicked',
        properties: {
          widget_type: e.widgetType,
          cta: e.label,
          destination_url: normalizeUrl(e.url),
        },
      })
    },
  ],
  'page-article-viewed': [
    e => {
      void gtmDatalayer({
        type: 'track',
        event: 'Article Viewed',
        properties: {
          url: normalizeUrl(e.url),
          category: e.categoryTitle,
          category_level: e.categoryLevel,
          author: e.author,
          published_date: e.date,
          title: e.title,
          word_count: e.wordCount,
        },
      })
    },
  ],
  'page-category-viewed': [
    e => {
      void gtmDatalayer({
        type: 'track',
        event: 'Category Viewed',
        properties: {
          url: normalizeUrl(e.url),
          category: e.title,
          category_level: e.level,
        },
      })
    },
  ],
  'page-tag-clicked': [
    e => {
      if (e.type === 'top-category') {
        void gtmDatalayer({
          type: 'track',
          event: 'Link Clicked',
          properties: {
            link_type: 'Category',
            cta: e.label,
            destination_url: normalizeUrl(e.url),
            location: 'Top of Post',
          },
        })
      }
      if (e.type === 'bottom-category') {
        void gtmDatalayer({
          type: 'track',
          event: 'Link Clicked',
          properties: {
            link_type: 'Category',
            cta: e.label,
            destination_url: normalizeUrl(e.url),
          },
        })
      }
      if (e.type === 'tag') {
        void gtmDatalayer({
          type: 'track',
          event: 'Link Clicked',
          properties: {
            link_type: 'Tag',
            cta: e.label,
            destination_url: normalizeUrl(e.url),
            location: 'Bottom of Post',
          },
        })
      }
    },
  ],
  'top-menu-tab-clicked': [
    e => {
      void gtmDatalayer({
        type: 'track',
        event: 'Button Clicked',
        properties: {
          button_type: 'Tab',
          button_use: 'Navigation',
          location: 'Top Menu',
          cta: e.label,
          destination_url: normalizeUrl(e.url),
        },
      })
    },
  ],
}

function normalizeUrl(url?: string): string {
  // if relative url, prepend with current domain
  if (url && !url.startsWith('http')) {
    return `${publicConfig('appUrl')}${url}`
  }
  return url ?? ''
}
