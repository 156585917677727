import { DiscriminateUnion } from 'shared-definitions/types'
import type { AppEvent } from 'shared-code/app-events'
import { clickedObjectIDsAfterSearch, convertedObjectIDs } from 'shared-code/algolia-insights'

export type MapAppEventListeners<T extends Record<K, string>, K extends keyof T, R> = {
  [V in T[K]]: ((props: DiscriminateUnion<T, K, V>) => R)[]
}

const algoliaConverted = new Set<string>()

export const appEventListeners: MapAppEventListeners<AppEvent, 'event', void> = {
  'user-authenticated': [],
  'discovery-sort-changed': [],
  'product-list-viewed': [],
  'product-list-clicked': [],
  'newsletter-signup': [],
  'widget-interaction': [],
  'page-article-viewed': [],
  'page-category-viewed': [],
  'page-tag-clicked': [],
  'discovery-changed': [],
  'top-menu-tab-clicked': [],
  'outbound-link': [
    ({ postMeta, desc }) => {
      if (!postMeta.postId) {
        return
      }

      const categories = postMeta.categories.map(i => i.label.toLowerCase())
      const hitAlgolia = ['reviews', 'the best', 'deals'].some(i => categories.includes(i))

      if (hitAlgolia) {
        void convertedObjectIDs({
          eventName: desc,
          objectIDs: [postMeta.postId.toString()],
        })
      }
    },
  ],
  'poll-login-triggered': [
    ({ postMeta }) => {
      if (!postMeta.postId) {
        return
      }

      void convertedObjectIDs({
        eventName: 'Poll login triggered',
        objectIDs: [postMeta.postId.toString()],
      })
    },
  ],
  'poll-voted': [
    ({ postMeta }) => {
      if (!postMeta.postId) {
        return
      }

      void convertedObjectIDs({
        eventName: 'Poll voted',
        objectIDs: [postMeta.postId.toString()],
      })
    },
  ],
  'search-result-clicked': [
    ({ queryId, objectId, position, desc }) => {
      void clickedObjectIDsAfterSearch({
        eventName: desc,
        queryID: queryId,
        objectIDs: [objectId],
        positions: [position],
      })
    },
  ],
  'page-scrolled': [
    ({ postMeta, progress }) => {
      if (!postMeta.postId) {
        return
      }

      const cacheKey = makeKey(postMeta.postId, 'page-scrolled')
      if (algoliaConverted.has(cacheKey)) {
        return
      }

      if (progress < 0.5) {
        return
      }

      if (progress >= 0.7) {
        const categories = postMeta.categories.map(i => i.label.toLowerCase())
        const hitAlgolia = ['how to'].some(i => categories.includes(i))

        if (hitAlgolia) {
          algoliaConverted.add(cacheKey)
          void convertedObjectIDs({
            eventName: 'Post scrolled',
            objectIDs: [postMeta.postId.toString()],
          })
          return
        }
      }

      if (progress >= 0.5) {
        const categories = postMeta.categories.map(i => i.label.toLowerCase())
        const hitAlgolia = ['reviews', 'features', 'news'].some(i => categories.includes(i))

        if (hitAlgolia) {
          algoliaConverted.add(cacheKey)
          void convertedObjectIDs({
            eventName: 'Post scrolled',
            objectIDs: [postMeta.postId.toString()],
          })
        }
      }
    },
  ],
}

export function mergeAppEventsListeners<T = MapAppEventListeners<AppEvent, 'event', void>>(
  base: T,
  additional: Partial<T>
): T {
  const result = { ...base }
  for (const key in additional) {
    const newVal = additional[key]
    const oldVal = result[key]

    if (newVal) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      result[key] = [...newVal, ...oldVal]
    }
  }
  return result
}

function makeKey(postId: number, event: string): string {
  return `${event}${postId}`
}
